import { jsonToPoint } from '~/helpers/database-conversions'
import { IndexedTranslation } from '~/server/export/translation/chapter-translations'
import { getChapter } from '~/services/database/chapters'
import { getSeries } from '~/services/database/series'
import Excel from 'exceljs'

const getXLSX = async (chapterId: string, translations: IndexedTranslation[]) => {
  const chapter = await getChapter(chapterId)
  const series = await getSeries(chapter.series_id)

  // Create a new workbook and worksheet
  const workbook = new Excel.Workbook()
  const worksheet = workbook.addWorksheet('Translations')

  // Series infos
  worksheet.addRow(['Series Title']);
  worksheet.addRow([series?.title]);
  worksheet.addRow(['Series Author']);
  worksheet.addRow([series?.author]);
  worksheet.addRow(['Series Client']);
  worksheet.addRow([series?.client]);
  worksheet.addRow([]); // Empty row for spacing

  // Chapter infos
  worksheet.addRow(['Chapter']);
  worksheet.addRow([chapter.index]);
  if(chapter.title){
    worksheet.addRow(['Chapter Title']);
    worksheet.addRow([chapter.title]);
  }
  worksheet.addRow([]); // Empty row for spacing

  // Translation entries headers
  worksheet.addRow(['Page Index', 'Entry Index', 'Position', 'Translated Text'])

  let pageIndex = 0
  let currentPageId: number | null = null

  translations.forEach((entry) => {
    if (currentPageId !== entry.page_id) {
      currentPageId = entry.page_id
      pageIndex++
    }
    const translation = entry.translated_text ?? ''
    const point = jsonToPoint(entry.start_point)
    const position = `${Math.round(point.x * 100)},${Math.round(point.y * 100)}`

    // Add a row for each translation entry
    worksheet.addRow([pageIndex, entry.index + 1, position, translation])
  })

  // Generate the buffer
  const buffer = await workbook.xlsx.writeBuffer()

  return new Blob([buffer])
}

export {
  getXLSX
}