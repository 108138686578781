import JSZip from "jszip"
import { jsonToPoint } from '~/helpers/database-conversions'
import { IndexedTranslation } from '~/server/export/translation/chapter-translations'
import { getChapter } from '~/services/database/chapters'
import { getSeries } from '~/services/database/series'

const getODT = async (chapterId: string, translations: IndexedTranslation[]) => {
  const chapter = await getChapter(chapterId)
  const series = await getSeries(chapter.series_id)

  const zip = new JSZip()

  // Add the mimetype file (uncompressed and first in the archive)
  zip.file("mimetype", "application/vnd.oasis.opendocument.text", { compression: "STORE" })

  // Add META-INF/manifest.xml
  zip.folder("META-INF")?.file(
    "manifest.xml",
    `<?xml version="1.0" encoding="UTF-8"?>
    <manifest:manifest xmlns:manifest="urn:oasis:names:tc:opendocument:xmlns:manifest:1.0">
        <manifest:file-entry manifest:full-path="/" manifest:media-type="application/vnd.oasis.opendocument.text"/>
        <manifest:file-entry manifest:full-path="content.xml" manifest:media-type="text/xml"/>
    </manifest:manifest>`
  )

  // Build content
  let content = ''
  
  // Add header content
  content += `<text:p>Series Title: ${series?.title || ''}</text:p>`
  content += `<text:p>Series Author: ${series?.author || ''}</text:p>`
  content += `<text:p>Series Client: ${series?.client || ''}</text:p>`
  content += `<text:p></text:p>`
  content += `<text:p>Chapter: ${chapter.index}</text:p>`
  
  if(chapter.title) {
    content += `<text:p>Chapter Title: ${chapter.title}</text:p>`
  }
  
  content += `<text:p></text:p>`
  content += `<text:p>Translation Entries:</text:p>`

  // Add translations content
  let pageIndex = 0
  let currentPageId: number | null = null

  translations.forEach((entry) => {
    if (currentPageId !== entry.page_id) {
      currentPageId = entry.page_id
      pageIndex++
      
      content += `<text:p></text:p>`
      content += `<text:h text:outline-level="2">#Page ${pageIndex}</text:h>`
    }

    const point = jsonToPoint(entry.start_point)
    const position = `${Math.round(point.x * 100)},${Math.round(point.y * 100)}`
    
    content += `<text:p>#${entry.index + 1} (Position: ${position})</text:p>`
    content += `<text:p>${entry.translated_text ?? ''}</text:p>`
  })

  // Add content.xml with the generated content
  zip.file(
    "content.xml",
    `<?xml version="1.0" encoding="UTF-8"?>
    <office:document-content 
        xmlns:office="urn:oasis:names:tc:opendocument:xmlns:office:1.0"
        xmlns:text="urn:oasis:names:tc:opendocument:xmlns:text:1.0"
        xmlns:style="urn:oasis:names:tc:opendocument:xmlns:style:1.0"
        xmlns:table="urn:oasis:names:tc:opendocument:xmlns:table:1.0"
        xmlns:draw="urn:oasis:names:tc:opendocument:xmlns:drawing:1.0"
        office:version="1.2"
        office:mimetype="application/vnd.oasis.opendocument.text">
        <office:body>
            <office:text>
                ${content}
            </office:text>
        </office:body>
    </office:document-content>`
  )

  // Generate the ZIP archive
  const blob = await zip.generateAsync({
    type: "blob",
    mimeType: "application/vnd.oasis.opendocument.text",
    compression: "DEFLATE", // Compress all files except mimetype
  })

  return blob
}

export { getODT }