import { SubmitHandler, createForm, valiForm } from '@modular-forms/solid'
import { Component } from 'solid-js'
import Button from '~/components/button/button'
import { ExportModalProps, TranslationsExportFormType, TranslationsExportSchema } from '~/components/export-translations-modal/export-translations-modal.interfaces'
import styles from '~/components/export-translations-modal/export-translations-modal.module.scss'
import formStyles from '~/components/forms/styles/complex-form.module.scss'
import ToggleField from '~/components/fields/toggle-field/toggle-field'
import Modal from '~/components/modal/modal'
import { setLoadingState } from '~/services/loading/loading'
import { translations } from '~/translations'
import { getTranslationsExport } from '~/services/export/translations'
import { TranslationExportFormat } from '~/types/database/chapter'

const ExportTranslationsModal: Component<ExportModalProps> = ({ open, setOpen, chapterId }) => {
  const [form, { Form, Field }] = createForm<TranslationsExportFormType>({
    initialValues: {
      xlsx: true,
      docx: false,
      ods: false,
      odt: false,
      txt: false,
    },
    validate: valiForm(TranslationsExportSchema),
  })

  const onSubmit: SubmitHandler<TranslationsExportFormType> = async (values) => {
    setLoadingState({
      loading: true
    })
    try {
      const formats: TranslationExportFormat[] = []
      if (values.docx) formats.push('docx')
      if (values.xlsx) formats.push('xlsx')
      if (values.ods) formats.push('ods')
      if (values.odt) formats.push('odt')
      if (values.txt) formats.push('txt')
      await getTranslationsExport({
        chapterId,
        formats
      })
    } catch (error) {
      console.error(error)
    }
    setLoadingState({
      loading: false
    })
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={translations().chapters.forms.export.title}
    >
      <Form onSubmit={onSubmit} class={styles.form}>
        <div class={formStyles.fields}>
          <Field name='xlsx' type='boolean'>
            {(field, props) => (
              <ToggleField
                label={translations().chapters.forms.export.translations.xlsx}
                {...field}
                {...props}
              />
            )}
          </Field>
          <Field name='docx' type='boolean'>
            {(field, props) => (
              <ToggleField
                label={translations().chapters.forms.export.translations.docx}
                {...field}
                {...props}
              />
            )}
          </Field>
          <Field name='ods' type='boolean'>
            {(field, props) => (
              <ToggleField
                label={translations().chapters.forms.export.translations.ods}
                {...field}
                {...props}
              />
            )}
          </Field>
          <Field name='odt' type='boolean'>
            {(field, props) => (
              <ToggleField
                label={translations().chapters.forms.export.translations.odt}
                {...field}
                {...props}
              />
            )}
          </Field>
          <Field name='txt' type='boolean'>
            {(field, props) => (
              <ToggleField
                label={translations().chapters.forms.export.translations.txt}
                {...field}
                {...props}
              />
            )}
          </Field>
        </div>
        <div class={styles.buttons}>
          <Button
            type='submit'
            style='lagoon'
            isLoading={form.submitting}
          >
            {translations().general.actions.export}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default ExportTranslationsModal