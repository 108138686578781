import { jsonToPoint } from '~/helpers/database-conversions'
import { IndexedTranslation } from '~/server/export/translation/chapter-translations'
import { getChapter } from '~/services/database/chapters'
import { getSeries } from '~/services/database/series'

const getTXT = async (chapterId: string, translations: IndexedTranslation[]) => {
  const chapter = await getChapter(chapterId)
  const series = await getSeries(chapter.series_id)
  let document = ''

  // Series infos
  document += `${series?.title} - ${series?.author} - ${series?.client}\n\n`

  // Chapter infos
  document += `Chapitre ${chapter.index}`
  if (chapter.title) document += ` ${chapter.title}`
  document += `\n`

  // Translation entries
  let pageIndex = 0
  let currentPageId: number | null = null
  const content: string[] = []
  translations.forEach((entry) => {
    if(currentPageId !== entry.page_id){
      currentPageId = entry.page_id
      pageIndex++
      content.push(`\n#Page ${pageIndex}`)
    }
    const translation = entry.translated_text
    const point = jsonToPoint(entry.start_point)
    const position = `${Math.round(point.x * 100)},${Math.round(point.y * 100)}`
    content.push(`#${entry.index + 1} (Position: ${position})\n${translation ?? ''}`)
  })

  document += content.join('\n')

  return new Blob([document], { type: "text/plain;charset=utf-8" })
}

export {
  getTXT
}