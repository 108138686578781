import { InferOutput, boolean, object } from 'valibot'
import { Database } from '~/types/supabase'

export interface ExportModalProps {
  open: boolean
  setOpen: (value: boolean) => boolean
  chapterId: Database['public']['Tables']['chapters']['Row']['id']
}

export const TranslationsExportSchema = object({
  xlsx: boolean(),
  docx: boolean(),
  odt: boolean(),  
  ods: boolean(),  
  txt: boolean(),
})

export type TranslationsExportFormType = InferOutput<typeof TranslationsExportSchema>