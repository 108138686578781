import FileSaver from 'file-saver'
import JSZip from 'jszip'
import { getChapterTranslations } from '~/server/export/translation/chapter-translations'
import { getIndexedPages } from '~/server/export/translation/indexed-pages'
import { getTXT } from '~/server/export/translation/txt'
import { getPages } from '~/services/database/pages'
import { ExportChapterTranslationPayload } from '~/types/database/chapter'
import { getSeries } from '~/services/database/series'
import { getChapter } from '~/services/database/chapters'
import { getXLSX } from '~/server/export/translation/xlsx'
import { getDOCX } from '~/server/export/translation/docx'
import { getODT } from '~/server/export/translation/odt'
import { getODS } from '~/server/export/translation/ods'

const getTranslationsExport = async ({ chapterId, formats }: ExportChapterTranslationPayload) => {
  const startTime = performance.now()

  // Get translations and indexed pages
  const chapter = await getChapter(chapterId)
  const series = await getSeries(chapter.series_id)
  const exportFilename = `${series?.title} - Chapitre ${chapter.index}`
  const pages = (await getPages(chapter.id)).filter(page => page.type === 'original').sort((a, b) => a.index - b.index)
  const translations = await getChapterTranslations(chapter.id, pages)
  const indexedPages = await getIndexedPages(translations, pages)

  // Create the zip file
  const zip = new JSZip()

  // For each file format, generate the file and add it to the zip
  formats.forEach(format => {
    switch (format) {
      case 'xlsx':
        zip.file(`${exportFilename}.xlsx`, getXLSX(chapter.id, translations))
        break
      case 'docx':
        zip.file(`${exportFilename}.docx`, getDOCX(chapter.id, translations))
        break
      case 'odt':
        zip.file(`${exportFilename}.odt`, getODT(chapter.id, translations))
        break
      case 'ods':
        zip.file(`${exportFilename}.ods`, getODS(chapter.id, translations))
        break
      case 'txt':
        zip.file(`${exportFilename}.txt`, getTXT(chapter.id, translations))
        break
    }
  })
  
  // Add the indexed pages to the zip file
  indexedPages.forEach((page, index) => {
    const pageIndex = `${index.toString().length > 1 ? '' : '0'}${index + 1}`
    const rawBase64 = page.dataURL.split('base64,')[1]
    zip.file(`page-${pageIndex}.png`, rawBase64, { base64: true })
  })

  // Generate the zip file and download it
  const zipBlob = await zip.generateAsync({ type: "blob" })
  FileSaver.saveAs(zipBlob, `${exportFilename} (translations).zip`)

  const endTime = performance.now()
  const generation_time = (endTime - startTime) / 1000
  console.log({ generation_time })
}

export {
  getTranslationsExport
}