import { jsonToPoint } from '~/helpers/database-conversions'
import { IndexedTranslation } from '~/server/export/translation/chapter-translations'
import { getChapter } from '~/services/database/chapters'
import { getSeries } from '~/services/database/series'
import { Document, Packer, Paragraph, TextRun } from 'docx'

const getDOCX = async (chapterId: string, translations: IndexedTranslation[]) => {
  const chapter = await getChapter(chapterId)
  const series = await getSeries(chapter.series_id)

  // Head of Document
  const head = [
    new Paragraph({
      children: [new TextRun("Series Title: "), new TextRun(series?.title || "")],
    }),
    new Paragraph({
      children: [new TextRun("Series Author: "), new TextRun(series?.author || "")],
    }),
    new Paragraph({
      children: [new TextRun("Series Client: "), new TextRun(series?.client || "")],
    }),
    new Paragraph({
        children: [new TextRun("")],
    }),
    new Paragraph({
      children: [new TextRun("Chapter: "), new TextRun(chapter.index.toString())],
    })
  ]
  if(chapter.title){
    head.push(
      new Paragraph({
        children: [new TextRun("Chapter Title: "), new TextRun(chapter.title)],
      })
    )
  }
  head.push(
    new Paragraph({
        children: [new TextRun("")],
    }),
    new Paragraph({
      children: [new TextRun("Translation Entries:")],
    })
  )

  // Translations entries grouped by page
  let pageIndex = 0
  let currentPageId: number | null = null
  const translationsEntries: Paragraph[] = []

  translations.forEach((entry) => {
    if (currentPageId !== entry.page_id) {
      currentPageId = entry.page_id
      pageIndex++
      
      // Add page header
      translationsEntries.push(
        new Paragraph({
          children: [new TextRun("")], // Empty line before page
        }),
        new Paragraph({
          children: [new TextRun(`#Page ${pageIndex}`)],
        })
      )
    }

    const point = jsonToPoint(entry.start_point)
    const position = `${Math.round(point.x * 100)},${Math.round(point.y * 100)}`

    // Add entry number and position
    translationsEntries.push(
      new Paragraph({
        children: [new TextRun(`#${entry.index + 1} (Position: ${position})`)],
      }),
      // Add translation text
      new Paragraph({
        children: [new TextRun(entry.translated_text ?? '')],
      })
    )
  })

  const doc = new Document({
    sections: [{ children: [
      ...head,
      ...translationsEntries
    ]}]
  })

  const blob = await Packer.toBlob(doc)

  return blob
}

export {
  getDOCX
}